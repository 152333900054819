
.dacmed__hero{
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
}

.dacmed__hero-content{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
  
}

.dacmed__hero-content h1{
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 72px;
  line-height: 125%;
  margin: 1rem 0;
}

.dacmed__hero-content p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  margin: 1rem 0;
}
.dacmed__hero-buttons{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}
.dacmed__hero-buttons button{
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  border: 3px solid var(--color-red);
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  padding: 1rem 2rem;
}
.contact-button{
  background: var(--color-red);
  color: var(--color-bg);
}
.learn-button{
  background: var(--color-bg);
  color: var(--color-red);
}

.dacmed__hero-image{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dacmed__hero-image img{
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1050px) {
  .dacmed__hero{
    flex-direction: column;
  }
  .dacmed__hero-content{
    margin: 0 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .dacmed__hero-buttons{
    margin-bottom: 2rem;
  }
  .dacmed__hero-image img{
    width: 80%;
    height: auto;
  }
  
}

@media screen and (max-width: 650px) {
  .dacmed__hero h1{
    font-size: 48px;
  }
  .dacmed__hero p{
    font-size: 16px;
  }

  .dacmed__hero-content{
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 490px) {
  .dacmed__hero h1{
    font-size: 36px;
  }
  .dacmed__hero p{
    font-size: 12px;
  }

  .dacmed__hero-content{
    margin: 0 0 2rem;
  }
  .dacmed__hero-buttons button{
    font-size: 12px;
  }
}