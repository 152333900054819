.dacmed__contact{
  font-family: var(--font-family);
  text-align: center;
}
.dacmed__contact h3{
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
}
.dacmed__contact p{
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
.dacmed__contact a{
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
}
.dacmed__contact-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dacmed__contact-item{
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: column;
}
@media screen and (max-width:750px) {
  .dacmed__contact-container{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 3rem;
  }
}