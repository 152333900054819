.dacmed__questions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
}

.dacmed__questions-title{
  padding-bottom: 2rem;
}
.dacmed__questions-title h2{
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
}
.dacmed__questions-title p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}
.dacmed__questions-accordion{
  width: 100%;
  flex-grow: 1;
}
.dacmed__questions-accordion h3{
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}
.dacmed__questions-accordion p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;  
}
.dacmed__questions-accordion_item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid black;
  padding: 1rem 0;  
  cursor: pointer;
}
.dacmed__questions-accordion_item:last-child{
  border-bottom: 1px solid black;
}
.dacmed__questions-accordion_item-image-up{
  transform: rotate(180deg);
  transition: all 0.75s 0.25s;
  padding: 1rem 0;

}
.dacmed__questions-accordion_item-image-down{
  transform: rotate(0);
  transition: all 0.75s 0.25s;
  padding: 1rem 0;

}
.title{
  padding: 1rem 0;
}
.answer{
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}
.answer.show{
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}

@media screen and (max-width: 750px) {
  .dacmed__questions-title h2{
    font-size: 36px;
  }
  .dacmed__questions-title p{
    font-size: 12px;
  }
  .dacmed__questions-accordion h3{
    font-size: 16px;
  }
  .dacmed__questions-accordion p{
    font-size: 16px;
  }
}