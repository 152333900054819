.dacmed__footer{
  /* background-color: var(--color-red); */
}
.dacmed__footer-section1 {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-black) ;
  padding-bottom: 4rem;
}

.dacmed__footer-section1 svg {
  color: var(--color-black);
  margin: 0.5rem;
  font-size: 18px;
  cursor: pointer;
}

.dacmed__footer-section1 img{
  height: 48px;
  width: auto;
}

.dacmed__footer-section2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}
.dacmed__footer-section2 p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;  
}

@media screen and (max-width:1050px) {
  .dacmed__footer-section1 img{
    height: 36px;
    width: auto;
  }
  .dacmed__footer-section1{
    padding-bottom: 2rem;
  }
}
@media screen and (max-width:750px) {
 
  .dacmed__footer-section2{
    flex-direction: column;
    gap: 0;
  }
  .dacmed__footer-section2 p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;  
  }
  
}