@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root{
  --font-family: 'Roboto', sans-serif;

  --color-bg:#FFFFFF;
  --color-red:#FB5348;
  --color-black:#232222;
  --color-med:#58EDFF;
}
