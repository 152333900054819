.dacmed__reviews{
 
  margin-top: 4rem;
}
.dacmed__reviews-title{
  display: flex;
  margin-bottom: 4rem;
  flex-direction: column;
  max-width: 60%;
}
.dacmed__reviews-title h1{
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin: 1rem 0;
}

.dacmed__reviews-content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
}

.dacmed__star{
  margin-bottom: 2rem;
}

.dacmed__reviews-content_tab{
  flex: 1;

}
.dacmed__reviews-content_tab p{
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 2rem;
}
.dacmed__reviews-content_tab h4{
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

@media screen and (max-width: 1050px){
  .dacmed__reviews{
    margin: 0;
  }
  .dacmed__reviews-title{
    margin-bottom: 2rem;
    max-width: 100%;
  }
  .dacmed__reviews-title h1{
    font-size: 36px;
  }
  .dacmed__reviews-content{
    flex-direction: column;
    gap: 2rem;
  } 
 
  .dacmed__reviews-content_tab p{
    max-width: 100%;
    font-size: 16px;
    margin-bottom: 0;
  }
  .dacmed__reviews-content_tab h4{
    font-size: 16px;
    font-weight: 400;
  }
  .dacmed__star{
    margin-bottom: 0;
  }
  .dacmed__star img{
    width: 16px;
    height: 16px;
  }
  .avatar{
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 650px) {
}
@media screen and (max-width: 490px) {
  .dacmed__reviews{
    margin: 0;
  }
}
