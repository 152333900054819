*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
body{
  background: var(--color-bg);
}

a{
  color: unset;
  text-decoration: none;
}

.app__navbar{
  display: flex;
  justify-content: space-around;
  align-items: center;  
  /* position: fixed;
  width: 100%; */
}

.section__pading{
  padding: 4rem 2rem;
}

.section__margin{
  margin: 1rem 2rem;
}


.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (min-width:1160px) {
  .section__pading{
    padding: 4rem 10rem;
  }
  .section__margin{
    margin: 1rem 10rem;
  }
}

@media screen and (max-width: 700px) {
  .section__pading{
    padding: 2rem;
  }
  .section__margin{
    margin: 2rem;
  }
}
@media screen and (max-width: 650px){
  .section__pading{
    padding: 2rem 2rem;
  }
  .section__margin{
    margin: 2rem 1rem;
  }
}