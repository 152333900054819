.dacmed__navbar{
  position: fixed;
  /* width: 90%; */
  left: 0;
  right: 0;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: var(--color-red);
  border-radius: 80px;
  /* margin: 1rem 2rem; */
  top: 0;
  height: 72px;
  z-index: 9999;
}

.dacmed__navbar-links{
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: center;
}

.dacmed__navbar-logo{
  margin-right: 2rem;
}

.dacmed__navbar-logo img{
  width: auto;
  height: 44px;
}

.dacmed__navbar-links_container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dacmed__navbar-contact{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dacmed__navbar-links_container p,
.dacmed__navbar-contact p,
.dacmed__navbar-menu_container p{
  color: var(--color-bg);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  margin: 0 1rem;

  cursor: pointer;
}

.dacmed__navbar-contact button,
.dacmed__navbar-menu_container button{
  padding: 0.5rem 1rem;
  color: var(--color-red);
  font-family: var(--font-family);
  background: var(--color-bg);
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  border-radius: 8px;
  border-width: 0;
  outline: none;
  cursor: pointer;
  /* white-space: nowrap; */
}

.dacmed__navbar-menu{
  margin-left: 1rem;
  display: none;
  position: relative;
}
.dacmed__navbar-menu svg{
  cursor: pointer;
}
.dacmed__navbar-menu_container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: var(--color-red);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.dacmed__navbar-menu_container p{
  margin: 1rem 0;
}

.dacmed__navbar-menu_container-links_contact{
  display: none;
}

/* @media screen and (min-width: 1160px) {
  
  
  
}  */

@media screen and (max-width: 1050px) {
  .dacmed__navbar-links_container{
    display: none;
  }
  
  .dacmed__navbar-menu{
    display: flex;
  }
  
} 

@media screen and (max-width: 700px) {
  .dacmed__navbar-logo img{
    width: auto;
    height: 32px;
  }
  
} 
  
@media screen and (max-width: 550px) {
  .dacmed__navbar{
    padding: 1rem;
  }
  .dacmed__navbar-logo img{
    width: auto;
    height: 28px;
  }
  .dacmed__navbar-menu{
    display: none;
  }
  
} 